export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/LCVP_browse": [4],
		"/LCVP_display": [5],
		"/LCVP": [3],
		"/about": [6],
		"/analyse": [7],
		"/downloads": [8],
		"/explore": [9],
		"/imprint": [10],
		"/learn": [11],
		"/lifegate": [12],
		"/login": [13],
		"/logout": [14],
		"/query_builder": [15],
		"/register": [16],
		"/search": [17],
		"/smon_viz": [18],
		"/test": [19],
		"/workflows": [20]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';